<script>
    import FullCalendar from 'svelte-fullcalendar';
    import dayGridPlugin from '@fullcalendar/daygrid';

    let showCalendar = false;
    const checkAvailabilityBtn = document.getElementById('check_availability');
    if (checkAvailabilityBtn !== null) {
        checkAvailabilityBtn.addEventListener("click", function () {
            showCalendar = !showCalendar;
        });
    }

    let datesstring = document.getElementById("availability_data").dataset.dates;
    let datesArr = JSON.parse(datesstring);


    let options = {
        dateClick: (event) => alert('date click! ' + event.dateStr),
        events: datesArr.map(date => ({"date": date, "title": "Reserved"})),
        initialView: 'dayGridMonth',
        plugins: [dayGridPlugin],
    };

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("calendar") === "on") {
        showCalendar = true;
    }
</script>

{#if showCalendar}
    <main>
        <FullCalendar {options}/>
    </main>
{/if}

<style>
    main {
        z-index: 11;
        position: absolute;
        right: 100px;
        top: 80px;
        background: white;
        padding: 20px;
    }
</style>
